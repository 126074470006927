<template>
  <div class="updateBox">
    <div class="headBox">利润统计</div>
    <el-card class="box-card">
      <div class="screenBox">
        <div class="screenTime">
          <span class="cus_span">
            筛选时间：
          </span>
          <el-date-picker
            v-model="broadcast_date"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            :clearable="false"
          ></el-date-picker>
        </div>
        <div class="account">
          <span class="cus_span">
            账号：
          </span>
          <el-select v-model="plate_form" placeholder="全部">
            <el-option
              v-for="item in options"
              :key="item.key"
              :label="item.title"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="screenBtn">
        <el-button type="primary" icon="icon iconfont icon-sousuo" @click="handleSearch">搜索</el-button>
      </div>
      <tablePage
        :tableData="tableData"
        :currentPage="currentPage"
        :height="tableHeight"
        :isHeadColor="true"
        :total="total"
        @current-change="currentChange"
        ref="tableList"
        v-loading="loading"
      >
        <el-table-column prop="broad_cast_date" label="日期"></el-table-column>
        <el-table-column prop="manager" label="账号"></el-table-column>
        <el-table-column prop="broadcast_flow" label="流水"></el-table-column>
        <el-table-column prop="profit" label="利润"></el-table-column>
      </tablePage>
    </el-card>
  </div>
</template>

<script>
import tableHeight from "mixins/tableHeight";
import api from "@/api/guildManageapi";
import tablePage from "components/tablePage";
const options = [{ key: -1, title: "全部", value: null }];
export default {
  name: "profitPage",
  components: {
    tablePage,
  },
  mixins: [tableHeight],
  data() {
    return {
      loading:true,
      broadcast_date: [this.$timeFormat(new Date().setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),'yyyy-mm-dd'), this.$timeFormat(new Date().getTime(), 'yyyy-mm-dd')],
      currentPage: 1,
      total: 0,
      tableData: [],
      options,
      plate_form: null,
      //限制结束日期不能大于当前时间
      pickerOptions: {
          disabledDate: (time) => {
              return time.getTime() < this.value1 || time.getTime() > Date.now();
          }
      }
    };
  },
  created() {
    this._getList();
    this._getOptions();
  },
  methods: {
    //获取后台table数据
    async _getList() {
      let params = {page: this.currentPage,broadcast_date:this.broadcast_date,plate_form:this.plate_form}
      const data = await api.getProfitList(params);
      if (data.status_code === 200) {
        this.tableData = data.message;
        this.total = data.meta.pagination.total;
        this.loading = false
      }
    },
    //获取后台select数据
    async _getOptions() {
      const data = await api.getOptionsList();
      if (data.status_code === 200) {
        this.options = [...this.options, ...data.message];
      }
    },
    //点击页数切换
    currentChange(val) {
      this.currentPage = val;
      this.loading = true
      this._getList();
    },
    //搜索
    handleSearch(){
      this.currentPage = 1
      this._getList()
    }
  },
};
</script>

<style lang="less" scoped>
@import "./css/headCss.less";
// .updateBox /deep/ .el-card.is-always-shadow {
//   height: 630px;
// }
.cus_span {
  font-size: 14px;
  color: #333333;
  display: inline-block;
  vertical-align: middle;
}
.screenBox {
  height: 58px;
  line-height: 58px;
}
.screenBox .screenTime {
  display: inline-block;
  vertical-align: middle;
  margin-right: 50px;
}
.screenBox .account {
  display: inline-block;
}
.screenBtn {
  height: 58px;
  line-height: 58px;
  margin-bottom: 10px;
}
.updateBox /deep/ .iconfont {
  font-size: 14px;
  margin-right: 4px;
}
.updateBox /deep/ .el-button--primary{
  border-radius: 2px;
}
.updateBox /deep/ .el-date-editor .el-range-separator{
  line-height: 29px;
}
.updateBox /deep/ .el-input__inner {
  height: 34px;
  line-height: 34px;
  // vertical-align: initial;
}
.updateBox /deep/ .el-date-editor .el-range__icon {
  line-height: 28px;
}
.updateBox /deep/ .el-button {
  width: 84px;
  height: 32px;
}
.updateBox /deep/ .el-button--primary {
  background: linear-gradient(270deg, #fe61a2 0%, #f39064 100%);
  box-shadow: 0px 3px 6px 0px rgba(244, 81, 11, 0.3);
}
.updateBox /deep/.el-input__icon{
  line-height: 34px;
}
</style>